// import shop from '../../api/shop'

// initial state
// shape: [{ id, quantity }]
// {
//   done: false,
//   text: "Foobar",
// }
const ls_key = 'TL';

const state = () => ({
  items: [],
  // todos: JSON.parse(window.localStorage.getItem('TODO_LIST') || '[]'),
  taskList: JSON.parse(window.localStorage.getItem(ls_key) || '[]'),
  activeFilter: 'todo',
  possibleFilters: ['all', 'todo', 'done'],
})

const getters = {
  taskList: state => state.taskList,
  activeFilter: state => state.activeFilter,
  totalTaskCount: state => state.taskList.length,
  doneTaskCount: state => state.taskList.filter(task => task.done).length,
  possibleFilters: state => state.possibleFilters,
};

const actions = {
  createTask({ commit }, text) {
    console.log('>> in action');
    commit('addTaskItem', {
      text,
      done: false,
    })
  },

  removeTodo({ commit }, task) {
    commit('removeTodo', task)
  },

  toggleTodo({ commit }, task) {
    commit('editTodo', { task, done: !task.done })
  },

  editTodo({ commit }, { task, value }) {
    commit('editTodo', { task, text: value })
  },

  toggleAll({ state, commit }, done) {
    state.todos.forEach((task) => {
      commit('editTodo', { task, done })
    })
  },

  clearCompleted({ state, commit }) {
    state.todos.filter(task => task.done)
      .forEach(task => {
        commit('removeTodo', task)
      })
  },
  setActiveFilter({ commit }, newFilter) {
    commit('setActiveFilter', newFilter)
  },

};

const mutations = {
  addTaskItem(state, newTodo) {
    state.taskList.push(newTodo);
    localStorage.setItem(ls_key, JSON.stringify(state.taskList));
  },

  removeTodo(state, task) {
    state.taskList.splice(state.taskList.indexOf(task), 1)
  },

  editTodo(state, { task, text = task.text, done = task.done }) {
    // editTodo(state, task, value) {
    const index = state.taskList.indexOf(task)
    console.log('>> in editTodo');
    console.log(index, task, text, done);
    state.taskList.splice(index, 1, {
      ...task,
      text,
      done,
    });
    localStorage.setItem(ls_key, JSON.stringify(state.taskList));
  },

  createNewTask: (state, task) => {
    const tt = task;
    console.log('new task: ', tt);
    state.users.unshift(task)
  },
  setActiveFilter: (state, newFilter) => {
    if (state.possibleFilters.includes(newFilter)) {
      state.activeFilter = newFilter;
    } else {
      console.log('filter passed, ' + newFilter + ', is not valid');
    }
  },

  // setUsers: (state, users) => (
  //     state.users = users
  // ),
  // addNewUser: (state, user) => state.users.unshift(user),
  // removeUser: (state, id) => (
  //     state.users.filter(user => user.id !== id),
  //     state.users.splice(user => user.id, 1)
  // )
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}