<template>
  <div class="body">
    <!-- <div class="background"></div> -->
    <div
      class="content page-margin"
      :class="[$vuetify.breakpoint.smAndDown ? 'mobile' : '']"
    >
      <div class="main">
        <div class="text-h2 title orange-fade bold">Hello, I'm Kevin Mims</div>
        <div class="subtitle">
          <div class="prefix">Software</div>
          <div class="suffix">
            <transition-group tag="div" class="word-group" name="slide">
              <div v-for="number in [currentWord]" v-bind:key="number">
                <div class="word">
                  {{ wordList[currentWord % wordList.length] }}
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>

      <div class="welcome">
        <!-- <h3 class="greet">
        Hi, I’m Kevin. Glad you are here! Please take a look around.
      </h3> -->
        <p class="intro">
          I am passionate about building solutions that solve business problems
          and deliver real value. I specialize in creating software for clients
          ranging from individuals and small-businesses all the way to large
          enterprise corporations. Do you want to develop an idea or solve a
          business problem, reach out!
        </p>
      </div>

      <div class="action">
        <v-btn
          tile
          x-large
          outlined
          class="action-button"
          :to="{ name: 'Contact', params: { userId: 123 } }"
        >
          Contact Me
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      message: "Click for slide",
      show: true,
      wordList: ["Architect", "Engineer", "Leader", "Coach"],
      currentWord: 0,
    };
  },
  mounted() {
    setInterval(() => {
      this.currentWord = this.currentWord + 1;
    }, 3000);
  },
};
</script>

<style scoped lang="scss">
.body {
  // height: 100%;

  .content {
    // padding: 15px;
    // // height: calc(100vh - 50px - 30px);
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;

    &.mobile {
      .main {
        margin-top: 50px;
        .title {
          font-size: 25px;
        }
        .subtitle {
          font-size: 30px;
        }
      }

      .action {
        text-align: center;
        .action-button {
          width: 100%;
        }
      }
    }

    .main {
      margin: 150px 0 0 0;

      // .title {
      //   // font-weight: 700;
      //   &-icon:before {
      //     font-size: 50px;
      //   }
      //   .highlight {
      //     font-weight: 500;
      //     letter-spacing: 1px;
      //   }
      // }

      .subtitle {
        margin-top: 10px;
        letter-spacing: 1px;
        font-size: 25px;
        font-weight: 300;
        display: grid;
        gap: 10px;
        grid-template-columns: auto 1fr;
        align-items: center;
        line-height: 35px;

        .prefix {
        }
        .suffix {
          overflow: hidden;
          text-align: left;
          .word-group {
            overflow: hidden;
            position: relative;
            height: 35px;
            width: auto;
          }
        }
      }
    }

    .welcome {
      // max-width: 600px;
      margin: 75px auto 0px;
      // text-align: center;

      .greet {
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 1px;
      }
      .intro {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 1px;
      }
    }

    .action {
      // text-align: center;
      margin-top: 75px;

      .button {
        text-decoration: none;
        color: white;
        border: 1px solid white;
        padding: 15px 25px;
        font-size: 20px;
        background: rgba(255, 255, 255, 0.1);
        line-height: 57px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .body {
    .content {
      .main {
        // margin: 50px 0 0 0;
        // text-align: left;
        // .title {
        //   font-size: 25px;
        // }
        // .subtitle {
        //   font-size: 20px;
        // }
      }
      .welcome {
        display: grid;
        grid-template-columns: 1fr;
        .greet {
          text-align: left;
          font-size: 20px;
        }
        .intro {
          text-align: left;
        }
      }
    }
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}

.slide-enter-active {
  transition: all 0.3s ease-out;
}

.slide-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
</style>
