import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
  //   themes: {
  //     light: {
  //       // primary: colors.red.lighten4, // #E53935
  //       // primary: colors.deepOrange.darken3,
  //       primary: '#181616',
  //       secondary: '#feeeb4',
        
  //       // secondary: colors.teal.darken1, // #FFCDD2
  //       third: colors.teal.accent1,
  //       white: colors.shades.white,
  //       accent: colors.indigo.base, // #3F51B5
  //       indigo1: colors.lightBlue, // colors.deepOrange.lighten5,
  //       orange1: colors.deepOrange.lighten3,
  //       mainBlack: "#181616",
  //       mainBiege: '#feeeb4',
  //       mainRed: '#E4002B',
  //       mainWhite: '#ffffff',

  //       mainBackground: '#ffffff',
  //       mainTextColor: '#181616',
  //       regItemBackground: '#f3f3f3',
  //     },
  //     dark: {
  //       // primary: colors.red.lighten4, // #E53935
  //       // primary: colors.deepOrange.darken3,
  //       primary: '#ffffff',
  //       // secondary: '#feeeb4',
        
  //       // secondary: colors.teal.darken1, // #FFCDD2
  //       third: colors.teal.accent1,
  //       white: colors.shades.white,
  //       accent: colors.indigo.base, // #3F51B5
  //       indigo1: colors.lightBlue, // colors.deepOrange.lighten5,
  //       orange1: colors.deepOrange.lighten3,
  //       mainBlack: "#181616",
  //       mainBiege: '#feeeb4',
  //       mainRed: '#E4002B',
  //       mainWhite: '#ffffff',
  //       // #292929
  //       mainBackground: '#121212',//'#181616',//#121212
  //       mainTextColor: '#ffffff',
  //       regItemBackground: '#292929',
  //     },
  //   },
  },
});
