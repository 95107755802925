<template>
  <v-app id="app">
    <div class="app-wrap">
      <Header></Header>
      <router-view />
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    Header: () => import("@/components/app/Header.vue"),
    Footer: () => import("@/components/app/Footer.vue"),
  },
  data: () => ({}),
};
</script>

<style lang="scss">
@import "css/fonts.css";
@import "css/icons.css";
@import "css/main.scss";

// #app {
//   height: 100%;
// }
</style>
